export function determineEarlyStakers(
  stakedCwebDays: number | undefined,
  stakedCweb: number | undefined
) {
  if (stakedCwebDays && stakedCweb) {
    const days = stakedCwebDays / stakedCweb;
    const currDate = new Date();
    const aprilSecond = new Date("2024-04-02");
    const differenceInMs = currDate.getTime() - aprilSecond.getTime();

    // Convert milliseconds to days
    const differenceInDays = differenceInMs / (1000 * 3600 * 24);
    return days > differenceInDays;
  }
}
