import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  HexCoded: any;
  TimeGql: any;
};

export type BoosterWindowGql = {
  __typename?: 'BoosterWindowGql';
  endsAt: Scalars['TimeGql'];
  rewardPool: Scalars['Float'];
  startsAt: Scalars['TimeGql'];
};

export type Mutation = {
  __typename?: 'Mutation';
  proxyBurnTransaction: Scalars['Boolean'];
  signalStaking: Scalars['Boolean'];
};


export type MutationProxyBurnTransactionArgs = {
  address: Scalars['String'];
  message: Scalars['String'];
};


export type MutationSignalStakingArgs = {
  address: Scalars['HexCoded'];
  message: Scalars['HexCoded'];
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  daysToLaunch: Scalars['Int'];
  getBoosterWindow?: Maybe<BoosterWindowGql>;
  stakingSignalFor?: Maybe<Scalars['TimeGql']>;
  stakingStatistics: StakingStatistics;
  stakingStatisticsFor: StakingStatistics;
  stakingStatisticsV2: StakingStatistics;
};


export type QueryRootStakingSignalForArgs = {
  address: Scalars['HexCoded'];
};


export type QueryRootStakingStatisticsForArgs = {
  address: Scalars['HexCoded'];
};

export type StakingStatistics = {
  __typename?: 'StakingStatistics';
  amountOfStakers: Scalars['Int'];
  averageStaking: Scalars['Float'];
  currentBoostForfeitedL2: Scalars['Float'];
  currentBoostStakeCweb: Scalars['Float'];
  currentStakedCweb: Scalars['Float'];
  forfeitedL2Rewards24H: Scalars['Float'];
  stakedCwebDays: Scalars['Float'];
  totalAccumulatedL2Rewards: Scalars['Float'];
  totalBoostRewards: Scalars['Float'];
  totalClaimedForfeitedL2Rewards: Scalars['Float'];
  totalForfeitedCwebCollateral: Scalars['Float'];
  totalForfeitedL2Rewards: Scalars['Float'];
  totalStakedCweb: Scalars['Float'];
  totalStakedCwebCollateral: Scalars['Float'];
  totalUnstakedCweb: Scalars['Float'];
};

export type BoosterWindowGqlFragment = { __typename?: 'BoosterWindowGql', startsAt: any, endsAt: any, rewardPool: number };

export type GetBoosterWindowQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBoosterWindowQuery = { __typename?: 'QueryRoot', getBoosterWindow?: Maybe<{ __typename?: 'BoosterWindowGql', startsAt: any, endsAt: any, rewardPool: number }> };

export type DaysToLaunchQueryVariables = Exact<{ [key: string]: never; }>;


export type DaysToLaunchQuery = { __typename?: 'QueryRoot', daysToLaunch: number };

export type StakingStatisticsFragment = { __typename?: 'StakingStatistics', totalStakedCweb: number, totalUnstakedCweb: number, currentStakedCweb: number, stakedCwebDays: number, totalAccumulatedL2Rewards: number, totalForfeitedL2Rewards: number, forfeitedL2Rewards24H: number, totalClaimedForfeitedL2Rewards: number, totalForfeitedCwebCollateral: number, totalStakedCwebCollateral: number, currentBoostStakeCweb: number, currentBoostForfeitedL2: number, totalBoostRewards: number, amountOfStakers: number, averageStaking: number };

export type GlobalStakingStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalStakingStatisticsQuery = { __typename?: 'QueryRoot', stakingStatistics: { __typename?: 'StakingStatistics', totalStakedCweb: number, totalUnstakedCweb: number, currentStakedCweb: number, stakedCwebDays: number, totalAccumulatedL2Rewards: number, totalForfeitedL2Rewards: number, forfeitedL2Rewards24H: number, totalClaimedForfeitedL2Rewards: number, totalForfeitedCwebCollateral: number, totalStakedCwebCollateral: number, currentBoostStakeCweb: number, currentBoostForfeitedL2: number, totalBoostRewards: number, amountOfStakers: number, averageStaking: number } };

export type LocalStakingStatisticsForQueryVariables = Exact<{
  address: Scalars['HexCoded'];
}>;


export type LocalStakingStatisticsForQuery = { __typename?: 'QueryRoot', stakingStatisticsFor: { __typename?: 'StakingStatistics', totalStakedCweb: number, totalUnstakedCweb: number, currentStakedCweb: number, stakedCwebDays: number, totalAccumulatedL2Rewards: number, totalForfeitedL2Rewards: number, forfeitedL2Rewards24H: number, totalClaimedForfeitedL2Rewards: number, totalForfeitedCwebCollateral: number, totalStakedCwebCollateral: number, currentBoostStakeCweb: number, currentBoostForfeitedL2: number, totalBoostRewards: number, amountOfStakers: number, averageStaking: number } };

export type SignalStakingMutationVariables = Exact<{
  message: Scalars['HexCoded'];
  address: Scalars['HexCoded'];
}>;


export type SignalStakingMutation = { __typename?: 'Mutation', signalStaking: boolean };

export const BoosterWindowGqlFragmentDoc = gql`
    fragment BoosterWindowGql on BoosterWindowGql {
  startsAt
  endsAt
  rewardPool
}
    `;
export const StakingStatisticsFragmentDoc = gql`
    fragment StakingStatistics on StakingStatistics {
  totalStakedCweb
  totalUnstakedCweb
  currentStakedCweb
  stakedCwebDays
  totalAccumulatedL2Rewards
  totalForfeitedL2Rewards
  forfeitedL2Rewards24H
  totalClaimedForfeitedL2Rewards
  totalForfeitedCwebCollateral
  totalStakedCwebCollateral
  currentBoostStakeCweb
  currentBoostForfeitedL2
  totalBoostRewards
  amountOfStakers
  averageStaking
}
    `;
export const GetBoosterWindowDocument = gql`
    query getBoosterWindow {
  getBoosterWindow {
    ...BoosterWindowGql
  }
}
    ${BoosterWindowGqlFragmentDoc}`;

/**
 * __useGetBoosterWindowQuery__
 *
 * To run a query within a React component, call `useGetBoosterWindowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoosterWindowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoosterWindowQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBoosterWindowQuery(baseOptions?: Apollo.QueryHookOptions<GetBoosterWindowQuery, GetBoosterWindowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoosterWindowQuery, GetBoosterWindowQueryVariables>(GetBoosterWindowDocument, options);
      }
export function useGetBoosterWindowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoosterWindowQuery, GetBoosterWindowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoosterWindowQuery, GetBoosterWindowQueryVariables>(GetBoosterWindowDocument, options);
        }
export type GetBoosterWindowQueryHookResult = ReturnType<typeof useGetBoosterWindowQuery>;
export type GetBoosterWindowLazyQueryHookResult = ReturnType<typeof useGetBoosterWindowLazyQuery>;
export type GetBoosterWindowQueryResult = Apollo.QueryResult<GetBoosterWindowQuery, GetBoosterWindowQueryVariables>;
export const DaysToLaunchDocument = gql`
    query daysToLaunch {
  daysToLaunch
}
    `;

/**
 * __useDaysToLaunchQuery__
 *
 * To run a query within a React component, call `useDaysToLaunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDaysToLaunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDaysToLaunchQuery({
 *   variables: {
 *   },
 * });
 */
export function useDaysToLaunchQuery(baseOptions?: Apollo.QueryHookOptions<DaysToLaunchQuery, DaysToLaunchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DaysToLaunchQuery, DaysToLaunchQueryVariables>(DaysToLaunchDocument, options);
      }
export function useDaysToLaunchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DaysToLaunchQuery, DaysToLaunchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DaysToLaunchQuery, DaysToLaunchQueryVariables>(DaysToLaunchDocument, options);
        }
export type DaysToLaunchQueryHookResult = ReturnType<typeof useDaysToLaunchQuery>;
export type DaysToLaunchLazyQueryHookResult = ReturnType<typeof useDaysToLaunchLazyQuery>;
export type DaysToLaunchQueryResult = Apollo.QueryResult<DaysToLaunchQuery, DaysToLaunchQueryVariables>;
export const GlobalStakingStatisticsDocument = gql`
    query GlobalStakingStatistics {
  stakingStatistics {
    ...StakingStatistics
  }
}
    ${StakingStatisticsFragmentDoc}`;

/**
 * __useGlobalStakingStatisticsQuery__
 *
 * To run a query within a React component, call `useGlobalStakingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalStakingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalStakingStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalStakingStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalStakingStatisticsQuery, GlobalStakingStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalStakingStatisticsQuery, GlobalStakingStatisticsQueryVariables>(GlobalStakingStatisticsDocument, options);
      }
export function useGlobalStakingStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalStakingStatisticsQuery, GlobalStakingStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalStakingStatisticsQuery, GlobalStakingStatisticsQueryVariables>(GlobalStakingStatisticsDocument, options);
        }
export type GlobalStakingStatisticsQueryHookResult = ReturnType<typeof useGlobalStakingStatisticsQuery>;
export type GlobalStakingStatisticsLazyQueryHookResult = ReturnType<typeof useGlobalStakingStatisticsLazyQuery>;
export type GlobalStakingStatisticsQueryResult = Apollo.QueryResult<GlobalStakingStatisticsQuery, GlobalStakingStatisticsQueryVariables>;
export const LocalStakingStatisticsForDocument = gql`
    query LocalStakingStatisticsFor($address: HexCoded!) {
  stakingStatisticsFor(address: $address) {
    ...StakingStatistics
  }
}
    ${StakingStatisticsFragmentDoc}`;

/**
 * __useLocalStakingStatisticsForQuery__
 *
 * To run a query within a React component, call `useLocalStakingStatisticsForQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalStakingStatisticsForQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalStakingStatisticsForQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useLocalStakingStatisticsForQuery(baseOptions: Apollo.QueryHookOptions<LocalStakingStatisticsForQuery, LocalStakingStatisticsForQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocalStakingStatisticsForQuery, LocalStakingStatisticsForQueryVariables>(LocalStakingStatisticsForDocument, options);
      }
export function useLocalStakingStatisticsForLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocalStakingStatisticsForQuery, LocalStakingStatisticsForQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocalStakingStatisticsForQuery, LocalStakingStatisticsForQueryVariables>(LocalStakingStatisticsForDocument, options);
        }
export type LocalStakingStatisticsForQueryHookResult = ReturnType<typeof useLocalStakingStatisticsForQuery>;
export type LocalStakingStatisticsForLazyQueryHookResult = ReturnType<typeof useLocalStakingStatisticsForLazyQuery>;
export type LocalStakingStatisticsForQueryResult = Apollo.QueryResult<LocalStakingStatisticsForQuery, LocalStakingStatisticsForQueryVariables>;
export const SignalStakingDocument = gql`
    mutation signalStaking($message: HexCoded!, $address: HexCoded!) {
  signalStaking(message: $message, address: $address)
}
    `;
export type SignalStakingMutationFn = Apollo.MutationFunction<SignalStakingMutation, SignalStakingMutationVariables>;

/**
 * __useSignalStakingMutation__
 *
 * To run a mutation, you first call `useSignalStakingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignalStakingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signalStakingMutation, { data, loading, error }] = useSignalStakingMutation({
 *   variables: {
 *      message: // value for 'message'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSignalStakingMutation(baseOptions?: Apollo.MutationHookOptions<SignalStakingMutation, SignalStakingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignalStakingMutation, SignalStakingMutationVariables>(SignalStakingDocument, options);
      }
export type SignalStakingMutationHookResult = ReturnType<typeof useSignalStakingMutation>;
export type SignalStakingMutationResult = Apollo.MutationResult<SignalStakingMutation>;
export type SignalStakingMutationOptions = Apollo.BaseMutationOptions<SignalStakingMutation, SignalStakingMutationVariables>;