import MyStake from "../../assets/Icons/my_stake.svg";
import {
  REACT_APP_REWARD_RATE_OFF,
  REACT_APP_TOTAL_COLLATE_OFF,
  REACT_APP_TOTAL_REWARD_OFF,
  REACT_APP_TOTAL_STAKE_OFF,
} from "../../conf";
import {
  StakingData,
  calculateL2Collateral,
  calculateRewards,
  calculateAPR,
  StakerType,
} from "../Staking/model";

type MyStatisticsCardProps = {
  model: StakingData | undefined;
  stakerType: StakerType | undefined;
};

const MyStatisticsCard = (props: MyStatisticsCardProps) => {
  const { model, stakerType } = props;
  return (
    <div className="data_card">
      <div className="data_card_header color_white">
        <div className="card_icon_container">
          <img
            className="card_icon shadow"
            src={MyStake}
            width={47}
            alt="mystake"
          ></img>
          <span className="stripe-right header_stripe"></span>
        </div>
        <p className="card_header_title ">My Coinweb Staking</p>
      </div>
      <div id="data_card_mystake_data">
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">Total staked</p>
          {REACT_APP_TOTAL_STAKE_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">
              {model !== undefined &&
              model.forAddr.currentStakedCweb !== undefined &&
              model.forAddr.currentStakedCweb !== null
                ? model.forAddr.currentStakedCweb.toLocaleString() + " CWEB"
                : "- -"}
            </p>
          )}
        </div>
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">
            Total L2 collateral
          </p>
          {REACT_APP_TOTAL_COLLATE_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">
              {model !== undefined &&
              model.forAddr.totalStakedCwebCollateral !== undefined &&
              model.forAddr.totalStakedCwebCollateral !== null &&
              model.forAddr.totalForfeitedCwebCollateral !== undefined &&
              model.forAddr.totalForfeitedCwebCollateral !== null
                ? calculateL2Collateral(model).toLocaleString() + " CWEB"
                : "- -"}
            </p>
          )}
        </div>
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">Total rewards</p>
          {REACT_APP_TOTAL_REWARD_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">
              {model !== undefined &&
              model.forAddr.totalAccumulatedL2Rewards !== undefined &&
              model.forAddr.totalAccumulatedL2Rewards !== null &&
              model.forAddr.totalForfeitedL2Rewards !== undefined &&
              model.forAddr.totalForfeitedL2Rewards !== null
                ? calculateRewards(model).toLocaleString() + " CWEB"
                : "- -"}
            </p>
          )}
        </div>
        <div className="data_card_grid_cell">
          <p className="cell_header cell_padding color_bone">Rewards rate</p>
          {REACT_APP_REWARD_RATE_OFF ? (
            <p className="cell_data color_white">N/A</p>
          ) : (
            <p className="cell_data color_white">
              {model !== undefined
                ? stakerType && stakerType === StakerType.Earlystaker
                  ? "30%"
                  : calculateAPR(model) + "%"
                : "- -"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyStatisticsCard;
