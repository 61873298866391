import tokenAbi from '../abi/CWEBAbi.json';
import { REACT_APP_RPC_URL_MAINNET, REACT_APP_TOKEN_ADDRESS } from '../conf';

// const config = {
//   tokenAbi,
//   tokenAddress: '0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04',
//   providerUrl: 'https://goerli.infura.io/v3/35b1142eeccb4f7d8faf9d97ef6cdd1a',
//   networkId: 5,
//   networkName: 'Goerli Testnet',
// };


const config = {
  tokenAbi,
  tokenAddress: REACT_APP_TOKEN_ADDRESS || '0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04',
  providerUrl: REACT_APP_RPC_URL_MAINNET,
  networkId: 1,
  networkName: 'Ethereum Mainnet'
};

export default config;
