import './styles.css';
import { useState, memo, useEffect, useCallback } from "react";
import {
    BoosterData,
    calculateTimer
} from './model'

type TimerProps = {
    booster: BoosterData
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const Timer = (props: TimerProps) => {
    const { booster, setActive } = props

    const [data, setData] = useState<[
        number | null, //Days
        number | null, //Hours
        number | null, //Minutes
        number | null, //Seconds
        boolean | null // true if active  booster window
        // false if next booster window 
        // null if booster window has  ended
    ]>([0, 0, 0, 0, null]);

    const getTimeUntil = useCallback(() => {
        if (booster.booster.startsAt !== 0) {
            const timerData = calculateTimer(booster)
            setData(timerData);
            setActive(timerData[4] === true);
        }
    }, [setActive, booster]);

    useEffect(() => {
        setInterval(() => getTimeUntil(), 1000);
        return () => getTimeUntil();
    }, [getTimeUntil]);
    
    return (
        data[4] !== null ?
            <>
                <p id='boost_window_title'>{data[4] ? "Active boost window ends in" : "Next boost window starts in"}</p>
                <div id="boost_time_container">
                    {data[0] !== null && data[0] > 0 ? <p id='boost_window'>
                        {data[0]} day
                        {data[0] !== 1 ? "s " : " "}
                        -&nbsp;</p> : null}
                    <p id='boost_window'> {data[1]} hour 
                        {data[1] !== null && data[1] > 1 ? "s " : " "}
                        -&nbsp; </p>
                    <p id='boost_window'> {data[2]} min -&nbsp;</p>
                    <p id='boost_window'> {data[3]} sec </p>
                </div>
            </>
            :
            null
    );
};

export default memo(Timer);
