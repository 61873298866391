import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./index.module.css";
type Props = {
  open: boolean;
  handleClose: () => void;
};


const StakingWarningModal = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box id={styles.modalContainer}>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>
                <p>Attention all stakers,</p>
                Please be informed that the current staking programme, initially
                planned to conclude upon the mainnet launch scheduled for Q1
                2024, will now remain active beyond this date. However, we are
                excited to announce that the ongoing staking program will
                continue to offer exciting opportunities and benefits
                exclusively to existing stakers as a token of appreciation for
                their loyalty and support. Please note that these additional
                benefits including double staking rewards are exclusively
                available for current stakers. The additional benefits will be
                lost if the tokens are unstaked before mainnet launch.
                <p>
                  For new stakers, the terms and conditions of the current
                  staking program will remain unchanged, without the inclusion
                  of any additional benefits. As of April 2nd 00:00 UTC, 2024,
                  staked assets can be unstaked without incurring loss of
                  collateral or staking rewards. Despite the extension, the
                  original rule of discontinuation after mainnet launch remains
                  intact.
                </p>
                <p> Thank you for your continued support.</p>
                <a
                  style={{ color: "#fff", cursor: "pointer" }}
                  href="https://medium.com/@Coinweb.io/update-on-mainnet-staking-and-roadmap-moving-forward-dc86b6667288"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Click here for more information.
                </a>
              </p>
              <div className={styles.greenBlock} onClick={props.handleClose}>
                <div className={styles.greenText}>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default StakingWarningModal;
