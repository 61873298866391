import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { REACT_APP_API_ENDPOINT } from "./conf";
import "./fonts/cheyenne-sans/CheyenneSans-Bold.ttf";
import "./fonts/cheyenne-sans/CheyenneSans-Medium.ttf";
import "./fonts/cheyenne-sans/CheyenneSans-Regular.ttf";

const endpoint = REACT_APP_API_ENDPOINT;

const httpLink = new HttpLink({
  uri: endpoint,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    resultCacheMaxSize: 5000000,
    typePolicies: {
      // Signal: {
      //   keyFields: ["signedMessage"],
      // },
      // StakingStatistics: {
      //   keyFields: ["totalAccumulatedL2Rewards"],
      // },
      // signalStaking:{
      // }
    },
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
