
import './styles.css';
import LP from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

type PendingProps = {
};

const PendingLine: React.FC<PendingProps> = ({children}) => (
    <Box id="pending_box">
        {children}
    <LP  id="linear_progress"/>
    </Box>
    );


export default PendingLine;

