import Input, { InputProps } from "@mui/material/Input";
import Button, { ButtonProps } from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Telegram from "./assets/Icons/telegram.svg";
import Youtube from "./assets/Icons/youtube.svg";
import Twitter from "./assets/Icons/twitter.svg";
import Facebook from "./assets/Icons/facebook.svg";
import Instagram from "./assets/Icons/instagram.svg";
import LinkedIn from "./assets/Icons/l-inked-in.svg";
import Divider, { DividerProps } from "@mui/material/Divider";
import InputAdornment, {
  InputAdornmentProps,
} from "@mui/material/InputAdornment";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

export const MetamaskButton = styled(Button)<ButtonProps>(() => ({
  borderadius: "6px!important",
  backgroundImage: "linear-gradient(to bottom, #aeaed1, #626089) !important",
  fontSize: "12px!important",
  boxShadow: "none !important",
  height: "44px!important",
  width: "100% !important",
  fontFamily: ' "DINosaur Med", "CheyenneMed", sans-serif!important',
  fontWeight: 700,
  fontStretch: "normal!important",
  fontStyle: "normal!important",
  lineHeight: "0.86!important",
  letterSpacing: "1.17px!important",
  color: "#fff!important",
  maxWidth: "175px !important",
}));

/**  MUI BlockCard Divider Styling  **/
export const StakeCardDivider = styled(Divider)<DividerProps>(() => ({
  backgroundColor: "#4f4f72 !important",
  height: "1px !important",
  borderBottomWidth: "1px !important",
  width: "auto!important",
  margin: "25px 86px 40px 86px !important",
  maxWidth: "1140px",
  "@media (max-width:1160px) ": {
    margin: "25px 40px 40px 40px !important",
  },
  "@media (max-width:600px) ": {
    margin: "20px 20px 4x0px 20px !important",
  },
}));
/**  MUI BlockCard Divider Styling  **/
export const StakeDivider = styled(Divider)<DividerProps>(() => ({
  backgroundColor: "#4f4f72 !important",
  height: "1px !important",
  borderBottomWidth: "1px !important",
  width: "auto!important",
  margin: "25px auto 40px auto !important",
  maxWidth: "1140px",
  "@media (max-width:1160px) ": {
    margin: "25px 20px 40px 20px !important",
  },
  "@media (max-width:600px) ": {
    margin: "25px auto 20px auto !important",
  },
}));
/**  MUI BlockCard Divider Styling  **/
export const StakeBlueDivider = styled(Divider)<DividerProps>(() => ({
  backgroundImage: " linear-gradient(to left, #067cfb, #07f6fe)",
  height: "2px !important",
  borderBottomWidth: "1px !important",
  margin: "0px auto 25px auto !important",
  width: "360px",
  borderRadius: "1px",
  "@media (max-width:600px) ": {
    margin: "0px 20px 20px 20px !important",
    width: "auto",
  },
}));
export const StakeFormControl = styled(FormControl)<FormControlProps>(() => ({
  // maxWidth: "360px",
  // width:"600px",
  // paddingRight: "500px",
  paddingRight: "60px",
  "@media (max-width:600px) ": {
    margin: "0px auto 20px auto !important",
  },
}));
export const StakeInput = styled(Input)<
  InputProps & { error: boolean | undefined }
>(({ error }) => ({
  paddingTop: "15px !important",
  paddingBottom: "16px !important",
  width: "100%",
  fontSize: "24px !important",
  color: error ? "#ff0063" : "#00c3ff ",


  "@media (max-width:600px) ": {
    paddingBottom: "0px !important",
  },
  ".input": {
    padding: "130px",
  },
  "	.Mui-disabled": {
    color: "#00c3ff  !important",
    webkitTextFillColor: "#00c3ff !important",
  },
  // for production
  ".css-cv470z-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "1px solid #ffffff !important",
  },
  ".css-148jjvm-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "1px solid #067dfb !important",
  },
  //for build
  ".MuiInput-underline:before": {
    borderBottom: "2px solid #067dfb !important",
  },
  "& .Mui-disabled": {
    color: "#fff !important",
    "-webkit-text-fill-color": "#fff !important",
    opacity: 0.5,
  },
}));
export const StakeInputAdornment = styled(InputAdornment)<InputAdornmentProps>(
  () => ({
    color: "#00c3ff ",
    fontFamily: '"DINosaur Med","CheyenneMed", sans-serif !important',
    fontSize: "24px !important",
    height: "auto",
    // marginBottom: "5px !important",
    //for production
    ".css-1pnmrwp-MuiTypography-root": {
      color: "#00c3ff ",
      fontFamily: '"DINosaur Med","CheyenneMed", sans-serif !important',
      fontSize: "24px !important",
    },
    ".css-18m8r0v": {
      color: "#00c3ff ",
      fontFamily: '"DINosaur Med","CheyenneMed", sans-serif !important',
      fontSize: "24px !important",
    },
    "& .Mui-disabled": {
      color: "#fff !important",
      "-webkit-text-fill-color": "#fff !important",
      opacity: 0.5,
    },
  })
);
//**** FOOTER COMPONENTS */
export const Icons = [
  {
    icon: Telegram,
    link: "https://t.me/coinweb",
    alt: "telegram",
  },
  {
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCPpfLBsOO7XDB1zeFUzIxZA",
    alt: "youtube",
  },
  {
    icon: Twitter,
    link: "https://twitter.com/coinwebofficial/",
    alt: "twitter",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/Coinweb.io/",
    alt: "facebook",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/coinweb.io/ ",
    alt: "instagram",
  },
  {
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/coinweb/",
    alt: "linkedIn",
  },
];
/**  MUI Footer Input Styling  **/
export const FooterInput = styled(Input)<InputProps>(() => ({
  height: "44px!important",
  width: "100% !important",
  fontFamily: '"DINosaur Bk", "CheyenneRegular", sans-serif',
  color: "#fff!important",
  fontSize: "14px",
  backgroundColor: "#4f4f72",
  borderRadius: "6px",
  fontWeight: 500,
  "&:hover": {
    boxShadow: `inset 0 0 0 2px #8c8da4`,
  },
  "&:after": {
    borderBottom: "none !important",
  },
  "&:before": {
    borderBottom: "none !important",
  },
  "input.MuiInput-input.MuiInputBase-input.css-mnn31": {
    padding: "0px !important",
    height: "100%",
    textIndent: "16px !important",
    opacity: 1,
    borderRadius: "6px",
    "&:hover": {
      boxShadow: `inset 0 0 0 2px #8c8da4`,
    },
    "&:focus": {
      boxShadow: `inset 0 0 0 2px #8c8da4`,
    },
    "&::-webkit-input-placeholder": {
      color: "#fff!important",
      opacity: 1,
      fontFamily: '"DINosaur Bk","CheyenneRegular", sans-serif!important',
    },
  },
  /* Textarea inner box styling for development */
  "textarea.MuiInput-input.MuiInputBase-input.css-66dh3a-MuiInputBase-input-MuiInput-input":
    {
      textIndent: "16px !important",
      padding: "4px 0px !important",
      "&:focus": {
        boxShadow: `none !important`,
      },
    },
  /* Textarea inner box styling for production */
  "textarea.MuiInput-input.MuiInputBase-input.MuiInputBase-inputMultiline.css-10oer18":
    {
      textIndent: "16px !important",
      padding: "4px 0px !important",
      "&:focus": {
        boxShadow: `none !important`,
      },
      "&::-webkit-input-placeholder": {
        color: "#fff!important",
        opacity: 1,
        fontFamily: '"DINosaur Bk", "CheyenneRegular",sans-serif!important',
      },
    },
}));

/**  MUI Footer Input Styling  **/
export const FooterButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: '"DINosaur Bd", "CheyenneBold", sans-serif!important',
  background: "linear-gradient(to bottom, #11BBF3, #074DE3)!important",
  height: "44px",
  boxShadow: "0 0 10px transparent !important",
  transition: "box-shadow 0.4s !important",
  letterSpacing: "1.17px !important",
  lineHeight: "42px !important",
  borderBottom: "none !important",
  borderRadius: "6px",
}));
/**  MUI Wallet Button  Styling  **/
export const WalletButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: '"DINosaur Bd", "CheyenneBold",sans-serif!important',
  height: "44px",
  boxShadow: "0 0 10px transparent !important",
  transition: "none !important",
  letterSpacing: "1.17px !important",
  lineHeight: "42px !important",
  borderBottom: "none !important",
  borderRadius: "6px",
  backgroundImage: " linear-gradient(to bottom, #119df3, #074de3)",
  width: "360px",
  fontSize: "14px",
  "&.Mui-disabled": {
    backgroundImage: "linear-gradient(to bottom, #aeaed1, #626089)",
    color: " rgb(255 255 255 / 50%)",
    opacity: 0.25,
  },
  "@media (max-width:600px) ": {
    width: "100%",
  },
}));

/**  MUI Wallet Button  Styling  **/
export const StakeButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: '"DINosaur Bd","CheyenneBold", sans-serif!important',
  height: "44px",
  boxShadow: "0 0 10px transparent !important",
  transition: "none !important",
  lineHeight: "0.86 !important",
  borderBottom: "none !important",
  borderRadius: "6px",
  fontSize: "14px",
  paddingRight: "23px",
  paddingLeft: "23px",
  textTransform: "inherit",
  backgroundImage: " linear-gradient(to bottom, #119df3, #074de3)",
  "@media (max-width:600px) ": {
    width: "calc(100%)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    justifyContent: "flex-start",
    display: "block",
  },
  "&.Mui-disabled": {
    backgroundImage:
      "background-image: linear-gradient(to bottom, #119df3, #074de3)",
    color: " rgb(255 255 255 / 50%)",
    opacity: 0.5,
  },
}));

/**  MUI Footer Paper Styling  **/
export const FooterPaper = styled(Paper)<PaperProps>(() => ({
  "&:hover": {
    boxShadow: "inset 0 0 0 2px #8c8da4",
  },
  /*PaperInput inner box styling for development"*/
  ".css-183wnb8-MuiInputBase-root": {
    borderRadius: "0px !important",
    marginLeft: "2px !important",
  },
  "input#Footer-input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input": {
    textIndent: "14px",
    padding: "0px !important",
  },
  /*PaperInput inner box styling for build"*/
  "div.MuiInputBase-root.MuiInputBase-colorPrimary.css-1vkuwrk": {
    borderRadius: "0px !important",
    marginLeft: "2px !important",
  },
  ".MuiInputBase-input.css-mnn31": {
    textIndent: "14px !important",
    "&::-webkit-input-placeholder": {
      color: "#fff!important",
      opacity: 1,
      fontFamily: '"DINosaur Bk","CheyenneRegular", sans-serif!important',
    },
  },
}));
