import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import useWindowSize from "./useWindowSize";
import NavBar from "./components/NavBar";
import Staking from "./components/Staking";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ContractProvider, WalletProvider } from "./contexts";
import StakingWarningModal from "./components/Modals/StakingWarningModal";

function App() {
  const width = useWindowSize();
  const [show, setShow] = useState(true);
  // State to store if Navbar is open at mobile screen
  const [activeMetamask, setActiveMetamask] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  const handleCloseModal = () => setOpenModal(false);

  const getLibrary = (provider: any) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  };

  useEffect(() => {
    window.process = {
      ...window.process,
    };
    if (typeof (window as any).ethereum !== "undefined") {
      setActiveMetamask(true);
    }
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      setShow(window ? window.pageYOffset <= 30 : true);
    };
  }, []);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContractProvider>
        <WalletProvider>
          <div className="App">
            <header>
              <div id="Background"></div>
              <NavBar show={show} width={width}></NavBar>
            </header>
            <main className="App-header">
              <StakingWarningModal
                open={openModal}
                handleClose={handleCloseModal}
              />

              <Staking width={width} activeMetamask={activeMetamask}></Staking>
            </main>
            <footer>
              <Footer width={width}></Footer>
            </footer>
          </div>
        </WalletProvider>
      </ContractProvider>
    </Web3ReactProvider>
  );
}

export default App;
