import { useEffect } from "react";
import {
  useSignalStakingMutation,
  SignalStakingMutationVariables,
} from "../../generated/graphql";

export function StakingSignal({
  message,
  address,
}: SignalStakingMutationVariables):
  | "ok"
  | "error"
  | "loading"
  | undefined
  | "error without mutation" {
  const [signalStakingMutation, { data, loading, error }] =
    useSignalStakingMutation({
      variables: {
        message: message,
        address: address,
      },
    });

  useEffect(() => {
    if (
      message !== "error" &&
      message !== "" &&
      message !== undefined &&
      signalStakingMutation
    ) {
      let auxAddress = address;
      let auxMessage = message;

      const callSignalStakingMutation = async () => {
        if (
          auxAddress &&
          auxAddress.length === 42 &&
          auxAddress.startsWith("0x")
        )
          auxAddress = auxAddress.substring(2, auxAddress.length);

        if (
          auxMessage !== undefined &&
          auxMessage !== "" &&
          auxMessage !== null &&
          auxMessage.length === 132 &&
          auxMessage.startsWith("0x")
        ) {
          auxMessage = auxMessage.substring(2, auxMessage.length);
        }

        await signalStakingMutation({
          variables: {
            message: auxMessage,
            address: auxAddress,
          },
        });
        // .catch(err => {
        //     return "error";
        // });
      };
      callSignalStakingMutation();
    }
  }, [message, address, signalStakingMutation]);

  if (data?.signalStaking === true) {
    return "ok";
  } else if (error) {
    return "error";
  } else if (loading) {
    return "loading";
  } else {
    return "error without mutation";
  }
}
