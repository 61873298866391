import Input, { InputProps } from "@mui/material/Input";
import Button, { ButtonProps } from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Telegram from "../../assets/Icons/telegram.svg";
import Youtube from "../../assets/Icons/youtube.svg";
import Twitter from "../../assets/Icons/twitter.svg";
import Facebook from "../../assets/Icons/facebook.svg";
import Instagram from "../../assets/Icons/instagram.svg";
import LinkedIn from "../../assets/Icons/l-inked-in.svg";

//**** FOOTER COMPONENTS */
export const Icons = [
  {
    icon: Telegram,
    link: "https://t.me/coinweb",
    alt: "telegram",
  },
  {
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCPpfLBsOO7XDB1zeFUzIxZA",
    alt: "youtube",
  },
  {
    icon: Twitter,
    link: "https://twitter.com/coinwebofficial/",
    alt: "twitter",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/Coinweb.io/",
    alt: "facebook",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/coinweb.io/ ",
    alt: "instagram",
  },
  {
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/coinweb/",
    alt: "linkedIn",
  },
];
/**  MUI Footer Input Styling  **/
export const FooterInput = styled(Input)<InputProps>(() => ({
  height: "44px!important",
  width: "100% !important",
  fontFamily: '"DINosaur Bk", "CheyenneRegular", sans-serif',
  color: "#fff!important",
  fontSize: "14px",
  backgroundColor: "#4f4f72",
  borderRadius: "6px",
  fontWeight: 500,
  "&:hover": {
    boxShadow: `inset 0 0 0 2px #8c8da4`,
  },
  "&:after": {
    borderBottom: "none !important",
  },
  "&:before": {
    borderBottom: "none !important",
  },
  "input.MuiInput-input.MuiInputBase-input.css-mnn31": {
    padding: "0px !important",
    height: "100%",
    textIndent: "16px !important",
    opacity: 1,
    borderRadius: "6px",
    "&:hover": {
      boxShadow: `inset 0 0 0 2px #8c8da4`,
    },
    "&:focus": {
      boxShadow: `inset 0 0 0 2px #8c8da4`,
    },
    "&::-webkit-input-placeholder": {
      color: "#fff!important",
      opacity: 1,
      fontFamily: '"DINosaur Bk", "CheyenneRegular", sans-serif!important',
    },
  },
  /* Textarea inner box styling for development */
  "textarea.MuiInput-input.MuiInputBase-input.css-66dh3a-MuiInputBase-input-MuiInput-input":
    {
      textIndent: "16px !important",
      padding: "4px 0px !important",
      "&:focus": {
        boxShadow: `none !important`,
      },
    },
  /* Textarea inner box styling for production */
  "textarea.MuiInput-input.MuiInputBase-input.MuiInputBase-inputMultiline.css-10oer18":
    {
      textIndent: "16px !important",
      padding: "4px 0px !important",
      "&:focus": {
        boxShadow: `none !important`,
      },
      "&::-webkit-input-placeholder": {
        color: "#fff!important",
        opacity: 1,
        fontFamily: '"DINosaur Bk", "CheyenneRegular", sans-serif!important',
      },
    },
}));

/**  MUI Footer Input Styling  **/
export const FooterButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: '"DINosaur Bd","CheyenneBold ", sans-serif!important',
  background: "linear-gradient(to bottom, #11BBF3, #074DE3)!important",
  height: "44px",
  boxShadow: "0 0 10px transparent !important",
  transition: "box-shadow 0.4s !important",
  letterSpacing: "1.17px !important",
  lineHeight: "42px !important",
  borderBottom: "none !important",
  borderRadius: "6px",
}));

/**  MUI Footer Paper Styling  **/
export const FooterPaper = styled(Paper)<PaperProps>(() => ({
  "&:hover": {
    boxShadow: "inset 0 0 0 2px #8c8da4",
  },
  /*PaperInput inner box styling for development"*/
  ".css-183wnb8-MuiInputBase-root": {
    borderRadius: "0px !important",
    marginLeft: "2px !important",
  },
  "input#Footer-input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input": {
    textIndent: "14px",
    padding: "0px !important",
  },
  /*PaperInput inner box styling for build"*/
  "div.MuiInputBase-root.MuiInputBase-colorPrimary.css-1vkuwrk": {
    borderRadius: "0px !important",
    marginLeft: "2px !important",
  },
  ".MuiInputBase-input.css-mnn31": {
    textIndent: "14px !important",
    "&::-webkit-input-placeholder": {
      color: "#fff!important",
      opacity: 1,
      fontFamily: '"DINosaur Bk","CheyenneRegular",  sans-serif!important',
    },
  },
}));
