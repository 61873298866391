import {
    useGlobalStakingStatisticsQuery,
    StakingStatistics
} from '../../generated/graphql';

export function GlobalStatistics():"loading" | "error" | null | undefined | StakingStatistics {
        const { data, error, loading } = useGlobalStakingStatisticsQuery({
            variables: {
            },
        })
          if (loading) {
            return "loading";
          }
          if (data) {
            return data.stakingStatistics;   
          }
        if(error || !data)
          {
            return "error";   
          }                                                                                                                        
    }

